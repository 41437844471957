/* Calendar.css */
.calendar {
    /* Your calendar styles */
    font-family: 'Arial', serif; /* Optional: Use a font you like */
    font-size: 1.1rem; /* Optional: Adjust the font size */
    text-align: center; /* Optional: Center the text */

  }
  .day {
    color: black; /* Change the color of the days */
  }
  .weekend {
    color: black; /* Change weekend dates (Saturday & Sunday) to black */
  }
  
  .highlight {
    background-color: rgba(179, 32, 7, 0.953); /* Highlight color for second and fourth Sundays */
    border-radius: 40%; /* Optional: Rounded corners for highlighted Sundays */
    border-spacing: 20px; /* Optional: Add some spacing between highlighted Sundays */
    color: white; /* Text color for highlighted Sundays */

  }
  
  .holiday {
    background-color: white; /* Background color for holidays */
    color: black; /* Text color for holidays */
  }
  
  .holiday-indicator {
    color: black; /* Color for the holiday indicator */
    font-weight: bold; /* Optional: Make it bold */
  }
  