.background-slider {
    position: relative;
    height: 100vh; /* Full viewport height */
    color: white; /* Text color */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7); /* Shadow for better visibility */
    background-size: cover;
    background-position: center;
    transition: background-image 1s ease-in-out;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  
  .welcome-content {
    color: white;
    text-align: right; /* Align text to the right */
    padding: 30px;
    padding-top: 25vh;
    display: flex;
    flex-direction: column;

  }
  
  .first-text, .second-text, .third-text, .fourth-text, .fifth-text {
    backdrop-filter: blur(1px); /* Optional blur effect */
  }
  
  .first-text {
    font-size: 2.5rem; /* Adjust font size */
    margin-bottom: 20px;
    color: rgba(255, 0, 0, 0.586);
    background-color: whitesmoke;
  }
  
  .second-text {
    font-size: 3rem;
  }
  
  .third-text, .fourth-text, .fifth-text {
    font-size: 2rem;
  }
  
  /* Dark overlay on background */
  .background-slider::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Dark overlay */
    z-index: 0;
  }
  
  /* Fade effect */
  .fade {
    opacity: 100;
    transition: opacity 1s ease-in-out;
  }
  
  /* Media query for smaller screens */
  @media (max-width: 480px) {
    .welcome-content {
      text-align: center; /* Center text */
      padding: 10px;
      padding-top: 20vh;
      gap: 1vh;
    }
  
    .first-text {
      font-size: 1.5rem; /* Adjust font size */
    }
  
    .second-text {
      font-size: 2rem;
    }
  
    .third-text, .fourth-text, .fifth-text {
      font-size: 1.5rem;
    }
  }
  