/* Header container */
.sticky-header {
    position: sticky;         /* Makes the header stick to the top */
    top: 0;                   /* Sticks at the top of the viewport */
    width: 100%;              /* Full-width */
    height: auto;             /* Adjust the height as needed */
    background-size: cover;   /* Makes the image cover the entire header */
    background-position: center; /* Center the background image */
    background-blend-mode: overlay; /* Blends the image with a color */
    background-attachment: fixed;   /* Fixed background */
    background-color: rgba(143, 104, 72, 0.70); /* The overlay color */
    display: flex;            /* Flex container */
    align-items: center;      /* Center content vertically */
    z-index: 1000;            /* Ensure it's on top of other content */
    backdrop-filter: blur(10px); /* Optional: Add a blur effect */
  }
  
  /* The content inside the header */
  .header-content {
    color: white;             /* Text color */
    text-align: left;         /* Align text to the left */
    display: flex;            /* Align items horizontally */
    padding: 10px;
    align-items: center;
    width: 100%;              /* Full-width */
    justify-content: space-between;
  }

  .coin-title {
    display: flex;
    align-items: center;
  }
  
  /* Image in the header */
  .header-image {
    width: 70px;              /* Adjust the width as needed */
    height: 70px;             /* Adjust the height as needed */
    border-radius: 50%;       /* Makes the image round */
    margin-right: 10px;       /* Add some spacing */
  }
  
  /* The show title */
  .show-title {
    font-size: 1.5rem;          /* Adjust the size of the title */
    font-family: 'Alike Angular', serif; /* Use a font you like */
    letter-spacing: 1.5px;    /* Add some spacing between letters */
  }
  
  /* Contact info on the right */
  .contact {
    font-size: 1.3rem;        /* Adjust the size of the contact text */
    font-family: 'Alike Angular', serif;
    letter-spacing: 1.5px;
    text-align: right;
    margin-left: 10px;
  }

  .call-button {
    color: white;
    background: none;
    border: none;
    padding: 0;
    text-decoration: underline;
    cursor: pointer;
  }
  
  .email-button {
    color: white;
    background: none;
    border: none;
    padding: 0;
    text-decoration: underline;
    cursor: pointer;
  }
  

  .email {
    font-family: 'Alike Angular', serif;
    letter-spacing: 1.5px;
  }

  .call {
    font-family: 'Alike Angular', serif;
    letter-spacing: 1.5px;
    margin-bottom: 3px;
  }

  @media (min-width: 481px) {
    .contact {
      margin-right: 15px;      /* Add some spacing on the right */
    }

    .call-button {
      font-size: 1.3rem;
    }

    .email-button {
      font-size: 1.3rem;
    }
  }
  
  @media (max-width: 480px) {
    .show-title {
      font-size: 3.5vw;         /* Further reduce the text size for smaller screens */
    }
  
    .contact {
      font-size: 3.2vw;         /* Adjust contact text size */
      text-align: right;
    }
  
    .header-image {
      width: 50px;            /* Make the image smaller on mobile */
      height: 50px;
    }

    
  }
  