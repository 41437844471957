.app {
  background-color: whitesmoke;
}

.fade-in {
  opacity: 0; 
  transition: opacity 1s ease; 
}

.fade-in.visible {
  opacity: 1;
  background-color: whitesmoke; 
}


@keyframes slideInLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

/* Animation classes */
.animate-slideInLeft {
  animation: slideInLeft 1s ease-out forwards;
}

.animate-slideInRight {
  animation: slideInRight 1s ease-out forwards;
}



.location-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  padding-top: 10px;
  height: 500px;
}

.location-image {
  width: 50%;
  height: 100%;
  
}

.location-text-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  padding-right: 20px;
  padding-left: 10px;
}

.calendar-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* min-height: 80vh; */
  text-align: center;
  padding-top: 110px;
  padding-bottom: 90px;
}

.calendar-day {
  font-size: 1.7rem;
  margin-bottom: 1rem;
  color: #007BFF;
}

.calendar-title {
  font-size: 1.2rem;
  margin-bottom: 1rem;
  color: rgba(179, 32, 7, 0.953);
}

.pdf-link {
  text-align: center;
  background-color: whitesmoke;
}

.pdf-link img.pdf-icon {
  width: 50%; /* Adjust the size */
  cursor: pointer;
}

.pdf-link p {
  margin-top: 20px;
  color: #007bff; /* Link color */
  font-size: 18px;
}

.googleMap-container {
  display: flex;             /* Flexbox layout to divide the screen into two sections */
  width: 100%;
  padding-top: 10px;             /* Add some padding around the section */
  padding-bottom: 10px;
  height: 500px;
}

.text-section {
  width: 50%;                /* Left half for text */
  padding-left: 20px;             /* Add some padding for better readability */
  padding-right: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;   /* Center content vertically */
  animation: slideInLeft 1s ease-out;
}

.googleMap {
  width: 50%;                /* Right half for the map */
  margin: 7px;
}

.ntcbanner {
  text-align: center; /* Center the banner */
  padding-top: 20px; /* Optional: Add some padding */
}

.ntcbanner-image {
  max-width: 100%; /* Scale the image proportionally to the container's width */
  height: auto; /* Maintain the aspect ratio */
}

@media (max-width: 480px) {
  .ntcbanner {
    padding-top: 15px;
  }

  .googleMap-container {
    padding-top: 25px;
    height: 100%;
    padding-bottom: 25px;
  }


  .text-section {
    padding-left: 7px;      /* Decrease left padding for small screens */
    padding-right: 5px;      /* Optionally adjust right padding too */
  }

  .text-section h1 {
    font-size: 5vw;           /* Further reduce the text size */
  }

  .text-section p {
    font-size: 3vw;
  }
  .location-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }
  
  .location-image {
    width: 80%;
    height: 100%;
    margin: 10px;
  }

  .location-text-section h1 {
    font-size: 5vw;
  }

  .location-text-section p {
    font-size: 3vw;
  }
  
  .location-text-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
  }
  .calendar-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* min-height: 80vh; */
    text-align: center;
    transform: scale(.85);
    padding-top: 1px;
    padding-bottom: 1px;
  }

  .pdf-link {
    /* margin-top: 20px; */
    text-align: center;
    /* padding-bottom: 40px; */
    /* position: relative; */
    /* bottom: 32vh; */
  }
  
  .pdf-link img.pdf-icon {
    width: 60%; /* Adjust the size */
    cursor: pointer;
  }
  
  .pdf-link h3 {
    margin-top: 20px;
    color: #007bff; /* Link color */
    font-size: 3vw;
  }

}
